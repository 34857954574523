<template>
  <v-container>
    <head-title text="Combustible"></head-title>
    <menu-grid :items="items"></menu-grid>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    MenuGrid: () => import("@/components/base/MenuGrid"),
  },
  data: () => ({
    permissions: [],
    items: [
      {
        title: "Abastos",
        ico: "tasks",
        to: { name: "tank-create" },
        permission: "_view_fuels",
      },
      {
        title: "Despachos",
        ico: "gas-pump",
        to: { name: "diesel-list" },
        permission: "_view_dispatch",
      },
      {
        title: "Vehículos",
        ico: "shuttle-van",
        to: { name: "vehicle-list" },
        permission: "_view_vehicle",
      },
    ],
  }),
};
</script>